@import 'stylesheets/constants.scss';

.button,
.button:hover {
  color: white;
  border-radius: 3px;
  border: none;
  font-weight: 700 !important;
}

.small {
  height: 35px;
  padding: 10px 15px !important;
}

.medium {
  height: 42px;
  font-weight: bold;
}

.primary {
  background-color: $purple-primary !important;
  color: white !important;
}

.secondary {
  background: $teal-primary !important;
  color: white !important;
}

.primaryOutline {
  border: 1px solid $button-purple !important;
  color: $button-purple !important;
  background: transparent !important;
}

.primaryGhost {
  border: none !important;
  color: $button-purple !important;
  background: transparent !important;
}

.disabled {
  background: #c7ccd6 !important;
}
