@import 'stylesheets/constants.scss';

.breadcrumb {
  font-size: 19px;
  line-height: 23px;
  background: clear;

  .link {
    color: $dark-link-color;
    font-weight: normal !important;
  }

  .separator {
    margin: 0 10px;
    width: 7px;
  }
}
