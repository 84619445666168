@import 'stylesheets/constants.scss';

.tableHeaderItem {
  font-size: 13px;
  font-weight: 500;
  color: $grey-secondary;
  border-bottom: 1px solid #f2f3f6;

  &:hover {
    cursor: pointer;
  }

  .sortIcon {
    width: 10px;
    height: 10px;
    margin-left: 8px;
  }
}
