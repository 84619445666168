.formTitle {
  color: #1f174e;
  margin-bottom: 45px;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
}

.signinForm {
  width: 50%;
  margin: 0 auto;

  .field {
    width: 100%;
    margin-bottom: 20px;

    label {
      color: #97a6c2;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  .input {
    width: 100%;
  }

  .passwordInputContainer {
    position: relative;

    .showPasswordIcon {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .loginButton {
    width: 100%;
    margin-top: 25px;
    background-color: #58136d;
    color: white;
    padding: 15px 10px;
  }
}

.forgotPasswordLink {
  margin-top: 25px;
}

.loginErrorMessage {
  display: block;
  text-align: left;
  color: #e0145d;
}
