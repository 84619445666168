@import 'stylesheets/constants.scss';

.adminLayout {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$header-height});
  margin-top: -#{$header-height};

  .leftSidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 275px;
  }

  .content {
    width: 100%;
    display: flex;
    padding: 25px;
  }
}
