@import 'stylesheets/constants.scss';

.appContent {
  height: 100%;
}

.pageContainer {
  padding-top: $page-container-padding;
}

.fullPageContainer {
  padding-top: 0;
}

div {
  outline: none;
}
