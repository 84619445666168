/*
  Style override for react-coastify
  https://fkhadra.github.io/react-toastify/how-to-style
*/

@import 'stylesheets/constants.scss';

// styles for toast container
.Toastify__toast-container {
  top: 65px;
  min-width: 500px;
  background: white;
  border-radius: 2px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  z-index: 99;
}

// styles for toast
.Toastify__toast {
  background: inherit;
  padding: 15px;
}

.Toastify__toast--success {
  color: $green-success;
  border: 2px solid $green-success;
}

.Toastify__toast--error {
  color: $red-error;
  border: 2px solid $red-error;
}

.Toastify__toast-body {
  background: white;
  width: 100%;
}

// styles for close button
.Toastify__close-button {
  color: inherit;
  margin-top: 7px;

  svg {
    width: 16px;
    height: 16px;
  }
}
