.layout {
  width: 80%;
  padding-top: 20px;
  margin: 0 auto;
}

.formField {
  width: 100%;
}

.createOrganizationForm {
  max-width: 500px;

  .formTitle {
    color: #1f174e;
    margin-bottom: 45px;
    font-size: 23px;
    font-weight: 500;
    line-height: 30px;
  }

  .field {
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }

  .fieldInput {
    width: 100%;
    padding: 10px 12px !important;
    display: block;
  }

  .fieldLabel {
    display: block;
    color: #97a6c2;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .passwordInputContainer {
    position: relative;

    .showPasswordIcon {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .loginButton {
    width: 100%;
    margin-top: 25px;
    background-color: #58136d;
    color: white;
    padding: 15px 10px;
  }
}

.forgotPasswordLink {
  margin-top: 25px;
}

.loginErrorMessage {
  display: block;
  text-align: left;
  color: #e0145d;
}
