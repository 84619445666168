.background {
  position: absolute;
  width: 125px;
  height: 125px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(220, 216, 240, 0.79);
  border-radius: 11px;
  font-size: 12px;
  z-index: 999;
}

.spinner {
  display: flex;
  align-items: center;
  height: 100%;
  color: #33325f;
  box-sizing: border-box;
  font-size: 12px;
}
