@import 'stylesheets/constants.scss';

.link {
}

.primary {
  color: $purple-primary !important;
}

.secondary {
  color: blue;
}
