.input {
  border: 1px solid rgba(197, 211, 226, 0.48);
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
}

.inputError {
  border-color: #e0145d;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: none;
}
