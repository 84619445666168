@import './toast.scss';
@import '~@fontsource/chivo/700.css';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  overflow: auto;
}

* {
  font-family: 'Lato', 'Microsoft YaHei', 'Heiti TC', sans-serif;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: 700 !important;
}

label {
  color: #97a6c2 !important;
  font-weight: 400;
  font-size: 16px;
}

input {
  &:disabled {
    background-color: #f6f8fb !important;
  }

  &:focus {
    border-color: #5d1470 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    box-shadow: none !important;
  }
}

select {
  &:focus {
    border-color: #5d1470 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    box-shadow: none !important;
  }
}
