@import 'stylesheets/constants.scss';

:global(#root) {
  .appHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 0 20px;
    background-color: #f4f0f6;

    .headerLeft {
      display: flex;
      align-items: center;
      width: 200px;

      .logo {
        width: 90px;
        height: inherit;
        margin-bottom: -5px;
      }
    }

    .headerRight {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 17px;
      color: $purple-primary;

      .actionsContainer {
        margin-right: 10px;
      }

      .userMenuContainer {
        display: flex;
        align-items: center;
        text-align: right;

        .userMenu {
          @include no-select;

          display: flex;
          align-items: center;

          .avatar {
            margin-left: 8px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .policyContainer {
        display: flex;
        align-items: center;
        text-align: right;

        .policyLink {
          color: #fff;
          font-size: 13px;
          margin-left: 45px;
        }
      }
    }
  }

  .userDropdown {
    margin-top: 11px;
    border-radius: 0 !important;
  }

  .dropdownItem {
    margin: 2px !important;
    padding: 12px 25px !important;

    span {
      line-height: 17px;
      color: #1f174e !important;
    }
  }
}
