.authLayout {
  width: 100vw;
  min-width: 720px;
  height: 100%;
  display: flex;

  .left {
    width: 30%;
    min-width: 320px;

    .banner {
      height: 100%;
      background-size: cover;
      background-image: url('../../../../assets/images/LoginBanner.png');
    }
  }

  .right {
    width: 70%;

    .main {
      display: flex;
      flex-direction: column;
      height: 100%;

      form {
        height: 100%;
        max-width: 500px !important;
      }
    }
  }

  a,
  a:hover,
  a:visited {
    color: #58136d;
  }
}
