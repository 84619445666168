.inputError {
  display: flex;
  height: 17px;
  flex-direction: row;
  justify-items: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: #e0145d;

  .errorIcon {
    display: block;
    background-image: url('../../../assets/images/ErrorIcon.svg');
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-right: 5px;
  }
}
