.modal {
  padding: 25px 20px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
    color: #1f174e;
    position: relative;
    margin-bottom: 40px;
  }

  .closeButton {
    position: absolute;
    top: 35px;
    right: 45px;
    width: 15px;
    height: 15px;
    background: url('../../../assets/images/ClosePopup.svg') no-repeat;
    cursor: pointer;
  }

  /* Modal Sizing  */
  &.modalSmall {
    width: 700px !important;
  }

  &.modalTiny {
    width: 550px !important;
  }

  &.modalMini {
    width: 500px !important;
  }

  /* Container Sizing */
  .container {
    &.containerSmall {
      padding: 20px 50px;
    }
  }
}
