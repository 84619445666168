@import 'stylesheets/constants.scss';

.sidebarContainer {
  height: 100vh;
  border-right: 1px solid #f2f3f6;
  padding: 0px 20px;

  .sidebar {
    padding-top: 25px;
  }

  .heading {
    color: #97a6c2;
    font-size: 19px;
    margin-bottom: 20px;
    padding: 0px 8px;
  }
}
