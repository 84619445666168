/* Color Palette */
$purple-primary: #58136d;
$purple-secondary: #4b0d62;
$teal-primary: #00b3c5;
$grey-primary: #ccc;
$grey-secondary: #97a6c2;
$violet: rgba(165, 133, 255, 0.24);
$white: #fff;
$purple-link-color: #58136d;
$dark-link-color: #33325f;
$green-success: #1aa38a;
$red-error: #e0145d;

/* Dimensions & Padding */
$page-container-padding: 35px;
$top-area-height: 87px;
$header-height: 36px;
$annotator-control-height: 36px;

/* Border Colors */
$button-purple: #5d1470;

/* Font Colors */
$text-dark: #0f0837;

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
