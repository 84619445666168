@import 'stylesheets/constants.scss';

.confirmationModal {
  padding: 20px 25px 20px;
  text-align: left;
  word-wrap: break-word;
  color: $text-dark;

  .container {
    text-align: left;

    .main {
      .heading {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 25px;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .message {
        margin: 15px auto;
      }
    }

    .actionButtons {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;

      .cancelButton {
        width: 45%;
      }

      .confirmButton {
        width: 45%;
      }
    }
  }
}

.tiny {
  width: 450px !important;
}

.small {
  width: 500px !important;
}

.large {
  width: 550px !important;
}
