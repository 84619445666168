.menuItem {
  color: #0f0837;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: bold;

  & > svg {
    margin-right: 10px;
  }
}

.active {
  background: #f7f2f8;
  color: #5d1470;
}
