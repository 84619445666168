@import 'stylesheets/constants.scss';

.formField {
  margin-bottom: 10px !important;

  & > label {
    font-weight: 400 !important;
    color: #97a6c2 !important;
    font-size: 16px !important;
    margin-bottom: 8px !important;
  }
}

.hasError {
  input,
  select {
    border: 1px solid $red-error !important;

    &:focus {
      @extend input;
    }
  }

  label {
    color: $red-error !important;
  }
}

.block {
  width: 100%;
}
