.searchArea {
  display: flex;
  flex-direction: row;
  height: 42px;
  width: 286px;
  padding-left: 20px;
  float: right;
  background: rgba(209, 221, 234, 0.17);
  border-radius: 3px;
  border: 1px solid #c5d3e2;

  .searchIcon {
    color: #33325f;
    font-size: 12px;
    height: 42px;
    line-height: 12px;
  }

  .searchTextArea {
    border: none;
    margin-left: 11px;
    background: transparent;
    font-size: 14px;
  }

  .searchTextArea::placeholder {
    color: #97a6c2;
  }

  .searchTextArea:focus {
    outline: none;
  }
}
