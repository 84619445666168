@import 'stylesheets/constants.scss';

.ghostButton {
  padding: 0 !important;
  margin: 0 5px !important;
  background: none !important;
  color: $purple-link-color !important;
}

.light {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}
