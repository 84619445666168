@import 'stylesheets/constants.scss';

.toast {
  @include no-select;

  display: flex;
  align-items: flex-start;

  .leftAccessory {
    width: 40px;
    height: 20px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .content {
    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .messageMarginTop {
      margin-top: 10px;
    }

    .messsage {
      font-size: 14px !important;
    }
  }
}
